'use client'

import Input from '@/components/Input/Input'
import Select from '@/components/Select/Select'
import Textarea from '@/components/Textarea/Textarea'
import classNames from 'classnames'
import ContactFormFieldError from './ContactFormFieldError/ContactFormFieldError'
import styles from './ContactFormField.module.scss'

const ContactFormField = ({
  className,
  ariaLabel,
  errorMessage,
  items,
  label,
  onChange,
  placeholder,
  type,
  value,
}: ContactFormFieldProps) => {
  const inputProps = { value, onChange, placeholder }
  let inputComponent

  switch (type) {
    case 'text':
      inputComponent = (
        <Input
          className={classNames(styles.formInputText, { [styles.formInputTextError]: errorMessage })}
          {...inputProps}
        />
      )
      break
    case 'textarea':
      inputComponent = (
        <Textarea
          className={classNames(styles.formInputText, styles.textArea, { [styles.formInputTextError]: errorMessage })}
          {...inputProps}
        />
      )
      break
    case 'select':
      if (Array.isArray(items)) {
        inputComponent = (
          <Select
            className={classNames(styles.formInputText, { [styles.formInputTextError]: errorMessage })}
            {...inputProps}
            ariaLabel={ariaLabel}
            items={items}
          />
        )
      }
      break
    default:
      inputComponent = null
  }

  return (
    <div className={classNames(className, styles.ContactFormField)}>
      {label && <label>{label}</label>}
      {inputComponent}
      <ContactFormFieldError message={errorMessage} />
    </div>
  )
}

export default ContactFormField
