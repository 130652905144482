'use client'

import { useState } from 'react'
import classNames from 'classnames'
import styles from './ContactForm.module.scss'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import ContactFormResults from './ContactFormResults/ContactFormResults'
import { handleTimeOfVisit, handleDateOfVisit, handlePhoneNumber, validateField } from '@/utils/contactForm'
import ContactFormField from '@/components/ContactForm/ContactFormField/ContactFormField'
import useCurrentPage from '@/hooks/use-current-page'

const ContactForm = ({ className }: ContactFormProps) => {
  const { i18n } = useI18n()
  const { currentLanguage } = useCurrentPage()

  const langConfig = {
    language: currentLanguage,
  }

  const locationsVisited = [
    i18n('californiaArcadia'),
    i18n('californiaCostaMesa'),
    i18n('californiaDowntownDisney'),
    i18n('californiaGlendale'),
    i18n('californiaLosAngeles'),
    i18n('californiaSanDiego'),
    i18n('californiaSantaClara'),
    i18n('californiaSantaMonica'),
    i18n('californiaTorrance'),
    i18n('nevadaLasVegas'),
    i18n('newYorkNyc'),
    i18n('oregonPortland'),
    i18n('oregonTigard'),
    i18n('washingtonBellevue'),
    i18n('washingtonSeattlePacificPlace'),
    i18n('washingtonSeattleUniversityVillage'),
    i18n('washingtonTukwila'),
  ]

  const reasonForContactingUs = [
    i18n('restaurantExperience'),
    i18n('mediaInquiries'),
    i18n('DonationRequests'),
    i18n('businessRequests'),
    i18n('generalQuestions'),
    i18n('other'),
  ]

  const defaultErrorMessage = i18n('defaultFieldError') ?? 'Error'

  const contactFormFields: ContactFormFieldInitialProps[] = [
    {
      name: 'reasonForContactingUs',
      value: '',
      required: true,
      errorMessage: i18n('requiredField') ?? defaultErrorMessage,
    },
    {
      name: 'firstName',
      value: '',
      required: true,
      errorMessage: i18n('requiredField') ?? defaultErrorMessage,
    },
    {
      name: 'lastName',
      value: '',
      required: true,
      errorMessage: i18n('requiredField') ?? defaultErrorMessage,
    },
    {
      name: 'emailAddress',
      value: '',
      required: true,
      errorMessage: i18n('invalidEmailAddress') ?? defaultErrorMessage,
    },
    {
      name: 'phoneNumber',
      value: '',
      required: true,
      errorMessage: i18n('invalidPhoneNumber') ?? defaultErrorMessage,
    },
    {
      name: 'locationVisited',
      value: '',
      required: false,
    },
    {
      name: 'dateOfVisit',
      value: '',
      required: false,
      errorMessage: i18n('invalidDateOfVisit') ?? defaultErrorMessage,
    },
    {
      name: 'timeOfVisit',
      value: '',
      required: false,
      errorMessage: i18n('invalidTimeOfVisit') ?? defaultErrorMessage,
    },
    {
      name: 'message',
      value: '',
      required: true,
      errorMessage: i18n('requiredField') ?? defaultErrorMessage,
    },
  ]

  const intialState: { [key: string]: string } = [...contactFormFields].reduce(
    (acc: { [key: string]: string }, field) => {
      acc[field.name] = field.value
      return acc
    },
    {},
  )

  const [loading, setLoading] = useState(false)
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false)
  const [formSubmit, setFormSubmit] = useState(false)
  const [formData, setFormData] = useState<{ [key: string]: string }>(intialState)
  const [validationErrors, setValidationErrors] = useState<{ field: string; errorMessage: string }[]>([])

  const handleChange = (fieldName: string) => (event: FormFieldChangeEvent) => {
    setValidationErrors(prevState => prevState.filter(error => error.field !== fieldName))

    const { value } = event?.target || { value: '' }
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const validationErrors: ValidationError[] = contactFormFields
      .map(field => {
        if (field.required && !formData[field.name]) {
          return { field: field.name, errorMessage: field.errorMessage || defaultErrorMessage }
        }

        const specialValidationFields = ['emailAddress', 'phoneNumber', 'dateOfVisit', 'timeOfVisit']

        if (specialValidationFields.includes(field.name)) {
          const isValid = validateField(field.name, formData[field.name])
          if (!isValid && formData[field.name]) {
            return { field: field.name, errorMessage: field.errorMessage || defaultErrorMessage }
          }
        }
        return null
      })
      .filter(Boolean) as ValidationError[]

    setValidationErrors(validationErrors)

    if (validationErrors.length > 0) {
      setFormSubmit(true)
      setFormSubmitSuccess(false)
      setLoading(false)
      return
    }

    try {
      /**
       * Fetch API to send the form data to the server
       */

      const formDataAndConfig = {
        ...formData,
        ...langConfig,
      }

      const response = await fetch('/api/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataAndConfig),
      })
      const data = await response.json()
      setFormSubmitSuccess(data?.success)
      if (data?.success) {
        setFormData(intialState)
      }
    } catch (error) {
      setFormSubmitSuccess(false)
      setLoading(false)
      console.error({ error })
    }

    setFormSubmit(true)
    setLoading(false)
  }

  return (
    <div className={classNames(className, styles.ContactForm)}>
      <form
        onSubmit={e => onSubmit(e)}
        noValidate
        autoComplete="on"
        name="ContactForm"
      >
        <div className={styles.formFields}>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="reasonForContactingUs"
              type={'select'}
              placeholder={i18n('reasonForContactingUs')}
              value={formData.reasonForContactingUs}
              onChange={handleChange('reasonForContactingUs')}
              ariaLabel={i18n('reasonForContactingUs')}
              items={reasonForContactingUs}
              errorMessage={validationErrors.find(error => error.field === 'reasonForContactingUs')?.errorMessage}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="firstName"
              type="text"
              placeholder={i18n('firstName')}
              value={formData.firstName}
              onChange={handleChange('firstName')}
              errorMessage={validationErrors.find(error => error.field === 'firstName')?.errorMessage}
            />
            <ContactFormField
              className={styles.field}
              name="lastName"
              type="text"
              placeholder={i18n('lastName')}
              value={formData.lastName}
              onChange={handleChange('lastName')}
              errorMessage={validationErrors.find(error => error.field === 'lastName')?.errorMessage}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="emailAddress"
              type="text"
              placeholder={i18n('emailAddress')}
              value={formData.emailAddress}
              onChange={handleChange('emailAddress')}
              errorMessage={validationErrors.find(error => error.field === 'emailAddress')?.errorMessage}
            />
            <ContactFormField
              className={styles.field}
              name="phoneNumber"
              type="text"
              placeholder={i18n('phoneNumber')}
              value={formData.phoneNumber}
              onChange={e => handlePhoneNumber('phoneNumber', e, handleChange)}
              errorMessage={validationErrors.find(error => error.field === 'phoneNumber')?.errorMessage}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="locationVisited"
              type="select"
              placeholder={i18n('locationVisited')}
              value={formData.locationVisited}
              onChange={handleChange('locationVisited')}
              items={locationsVisited}
              ariaLabel={i18n('locationVisited')}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="dateOfVisit"
              type="text"
              placeholder={i18n('dateOfVisit')}
              value={formData.dateOfVisit}
              onChange={e => handleDateOfVisit('dateOfVisit', e, handleChange)}
              errorMessage={validationErrors.find(error => error.field === 'dateOfVisit')?.errorMessage}
            />
            <ContactFormField
              className={styles.field}
              name="timeOfVisit"
              type="text"
              placeholder={i18n('timeOfVisit')}
              value={formData.timeOfVisit}
              onChange={e => handleTimeOfVisit('timeOfVisit', e, handleChange)}
              errorMessage={validationErrors.find(error => error.field === 'timeOfVisit')?.errorMessage}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <ContactFormField
              className={styles.field}
              name="message"
              type="textarea"
              placeholder={i18n('message')}
              value={formData.message}
              onChange={handleChange('message')}
              errorMessage={validationErrors.find(error => error.field === 'message')?.errorMessage}
            />
          </div>
        </div>
        <div className={styles.submitContainer}>
          <Button
            className={styles.submitButton}
            type="submit"
            secondary
            icon="caretRight"
            label={loading ? i18n('pleaseWait') : i18n('submit')}
            disabled={loading}
          />
        </div>
      </form>
      <div className={styles.FormResults}>
        {validationErrors.length > 0 ? (
          <ContactFormResults
            status="error"
            errorMessage={
              <>
                <p>{i18n('pleaseFillTheRequiredFields')}</p>
              </>
            }
          />
        ) : formSubmit && !loading ? (
          formSubmitSuccess ? (
            <ContactFormResults
              status="success"
              successMessage={
                <>
                  <p>{i18n('thankYouForContactingUs')}</p>
                </>
              }
            />
          ) : (
            <ContactFormResults
              status="error"
              errorMessage={
                <>
                  <p>{i18n('somethingWentWrong')}</p>
                </>
              }
            />
          )
        ) : null}
      </div>
    </div>
  )
}

export default ContactForm
